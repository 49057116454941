import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation, Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import ImageUploadSection from "./components/ImageUploadSection";
import AgriHyphenAIExplanation from "./components/AgriHyphenAIExplanation";
import Team from "./components/Team";
import Loader from "./components/Loader";
import "./i18n"; // Initialize i18n for translations
import DonatePage from "./components/DonatePage";
import Dashboard from "./components/Dashboard";
import Blogs from "./components/Blogs";
import ClimateAction from "./components/ClimateAction";

// Create a theme instance
const theme = createTheme({
  palette: {
    primary: {
      main: '#10a37f', // OpenAI's signature green
      light: 'rgba(16, 163, 127, 0.1)',
      dark: '#0a8c6d',
    },
    secondary: {
      main: '#2d333a', // Dark gray for text
      light: '#6e7681', // Light gray for secondary text
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#2d333a',
      secondary: '#6e7681',
    },
  },
  typography: {
    fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem',
      lineHeight: 1.2,
    },
    h2: {
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 1.3,
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0px 1px 2px rgba(0, 0, 0, 0.05)',
    '0px 2px 4px rgba(0, 0, 0, 0.05)',
    '0px 4px 8px rgba(0, 0, 0, 0.05)',
    '0px 8px 16px rgba(0, 0, 0, 0.05)',
    '0px 16px 32px rgba(0, 0, 0, 0.05)',
    ...Array(19).fill('none'), // Fill remaining shadows
  ],
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '8px 16px',
          fontSize: '0.9375rem',
          fontWeight: 500,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
          border: '1px solid rgba(0, 0, 0, 0.08)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          backgroundColor: '#ffffff',
        },
      },
    },
  },
});

function LoaderWrapper({ isLoading, setIsLoading }) {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1900);
    } else {
      setIsLoading(false);
    }
  }, [location, setIsLoading]);

  return isLoading ? (
    <Loader isLoading={isLoading} setIsLoading={setIsLoading} />
  ) : null;
}

function AppContent({ isLoading, setIsLoading }) {
  const location = useLocation();

  // Define paths where the Navbar should not be displayed
  const hideNavbarRoutes = ["/test-ai"];
  const shouldShowNavbar = !hideNavbarRoutes.includes(location.pathname);

  return (
    <>
      <LoaderWrapper isLoading={isLoading} setIsLoading={setIsLoading} />
      {!isLoading && (
        <>
          {/* Conditionally render Navbar */}
          {shouldShowNavbar && <Navbar />}
          <Routes>
            <Route path="/" element={<HeroSection />} />
            <Route path="/test-ai" element={<ImageUploadSection />} />
            <Route path="/info" element={<AgriHyphenAIExplanation />} />
            <Route path="/team" element={<Team />} />
            <Route path='/donate' element={<DonatePage/>}/>
            <Route path='/dashboard' element={<Dashboard/>}/>
            <Route path='/blogs' element={<Blogs/>}/>
            <Route path='/climate-action' element={<ClimateAction/>}/>
          </Routes>
        </>
      )}
    </>
  );
}

function App() {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppContent isLoading={isLoading} setIsLoading={setIsLoading} />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
