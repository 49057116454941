import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  BarChart, Bar
} from 'recharts';
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Grid,
  CircularProgress,
  TextField,
  useTheme,
  Button,
  Alert,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { format } from 'date-fns';

const Dashboard = () => {
  const theme = useTheme();
  const [stats, setStats] = useState({
    daily: null,
    weekly: null,
    monthly: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: '',
    end: ''
  });

  const fetchStats = async () => {
    setLoading(true);
    setError(null);
    try {
      const params = {};
      if (dateRange.start) {
        params.start_date = dateRange.start;
      }
      if (dateRange.end) {
        params.end_date = dateRange.end;
      }

      const [dailyRes, weeklyRes, monthlyRes] = await Promise.all([
        axios.get('http://192.168.245.59:8000/stats/daily', { params: { start_date: params.start_date, end_date: params.end_date } }),
        axios.get('http://192.168.245.59:8000/stats/weekly', { params: { start_date: params.start_date, end_date: params.end_date } }),
        axios.get('http://192.168.245.59:8000/stats/monthly', { params: { start_date: params.start_date?.slice(0, 7), end_date: params.end_date?.slice(0, 7) } })
      ]);

      setStats({
        daily: dailyRes.data,
        weekly: weeklyRes.data,
        monthly: monthlyRes.data
      });
    } catch (err) {
      console.error('Error fetching stats:', err);
      setError('Failed to fetch statistics');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  const formatData = (data, type) => {
    if (!data) return [];
    const statsKey = `${type}_stats`;
    return data[statsKey].map(({ date, count }) => ({
      date,
      count
    }));
  };

  const handleDateChange = (field, value) => {
    setDateRange(prev => ({
      ...prev,
      [field]: value
    }));
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: { xs: 2, md: 3 }, mt: 8, backgroundColor: theme.palette.background.default }}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ mb: { xs: 2, md: 0 } }}>Usage Analytics Dashboard</Typography>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, alignItems: 'center' }}>
          <TextField
            type="date"
            label="Start Date"
            value={dateRange.start}
            onChange={(e) => handleDateChange('start', e.target.value)}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
          <TextField
            type="date"
            label="End Date"
            value={dateRange.end}
            onChange={(e) => handleDateChange('end', e.target.value)}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
          <Button 
            variant="contained" 
            onClick={fetchStats}
            startIcon={<RefreshIcon />}
          >
            Update
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Requests (Daily)
              </Typography>
              <Typography variant="h4">
                {stats.daily?.total_requests || 0}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Period: {stats.daily?.period?.start} to {stats.daily?.period?.end}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Requests (Weekly)
              </Typography>
              <Typography variant="h4">
                {stats.weekly?.total_requests || 0}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Period: {stats.weekly?.period?.start} to {stats.weekly?.period?.end}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Requests (Monthly)
              </Typography>
              <Typography variant="h4">
                {stats.monthly?.total_requests || 0}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Period: {stats.monthly?.period?.start} to {stats.monthly?.period?.end}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={3}>
        {/* Daily Trend */}
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Daily Usage Trend
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={formatData(stats.daily, 'daily')}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="count" 
                    stroke={theme.palette.primary.main} 
                    name="Requests"
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Weekly Stats */}
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Weekly Distribution
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={formatData(stats.weekly, 'weekly')}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar 
                    dataKey="count" 
                    fill={theme.palette.secondary.main} 
                    name="Requests"
                  />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Monthly Overview */}
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Monthly Overview
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={formatData(stats.monthly, 'monthly')}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="count" 
                    stroke={theme.palette.success.main} 
                    name="Requests"
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;