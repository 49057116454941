import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Paper,
  Grid,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// Styled components
const PageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  marginBottom: theme.spacing(4),
  position: 'relative',
  display: 'inline-block',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -10,
    left: 0,
    width: '40%',
    height: 4,
    backgroundColor: '#10a37f',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  color: '#10a37f',
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
  boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const HighlightBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(16, 163, 127, 0.05)',
  borderLeft: '4px solid #10a37f',
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: '0 4px 4px 0',
}));

const ClimateAction = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine which language to use for the climate action content
  const isKirundi = i18n.language === 'kir';
  const isSwahili = i18n.language === 'swa';
  
  // For Kirundi, use French content
  // For Swahili, use English content
  // For English and French, use their respective content
  const shouldUseFrenchContent = isKirundi || i18n.language === 'fr';
  
  // Check if we should display the full translated content from the translation file
  if (shouldUseFrenchContent && t('climate_action_fr', { defaultValue: '' }) !== '') {
    // Display the pre-translated content for French/Kirundi
    const paragraphs = t('climate_action_fr').split('\n\n');
    
    return (
      <Container maxWidth="lg" sx={{ mt: 12, mb: 8 }}>
        <PageTitle variant={isMobile ? 'h4' : 'h3'} component="h1" gutterBottom>
          {t('Climate Action')}
        </PageTitle>
        
        <ContentPaper>
          <Box component="img" 
            src="https://images.unsplash.com/photo-1500382017468-9049fed747ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80"
            alt="Sustainable agriculture landscape"
            sx={{
              width: '100%',
              height: { xs: 200, sm: 300, md: 400 },
              objectFit: 'cover',
              borderRadius: 1,
              mb: 4
            }}
          />
          
          {paragraphs.map((paragraph, index) => {
            // Check if this is a heading (no period at the end and relatively short)
            const isHeading = !paragraph.includes('.') && paragraph.length < 100;
            
            return isHeading ? (
              <Typography 
                key={index} 
                variant={index === 0 ? "h4" : "h5"} 
                component={index === 0 ? "h2" : "h3"} 
                gutterBottom 
                fontWeight="bold" 
                color="#10a37f"
                sx={{ mt: index === 0 ? 0 : 4 }}
              >
                {paragraph}
              </Typography>
            ) : (
              <Typography 
                key={index} 
                variant="body1" 
                paragraph 
                sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}
              >
                {paragraph}
              </Typography>
            );
          })}
        </ContentPaper>
      </Container>
    );
  }

  // Default English/Swahili content with styled components
  return (
    <Container maxWidth="lg" sx={{ mt: 12, mb: 8 }}>
      <PageTitle variant={isMobile ? 'h4' : 'h3'} component="h1" gutterBottom>
        {t('Climate Action')}
      </PageTitle>
      
      <ContentPaper>
        <Box component="img" 
          src="https://images.unsplash.com/photo-1500382017468-9049fed747ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80"
          alt="Sustainable agriculture landscape"
          sx={{
            width: '100%',
            height: { xs: 200, sm: 300, md: 400 },
            objectFit: 'cover',
            borderRadius: 1,
            mb: 4
          }}
        />
        
        <Typography variant="h4" component="h2" gutterBottom fontWeight="bold" color="#10a37f">
          {t('Agrihyphen AI: Empowering Sustainable Agriculture for a Healthier Planet')}
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}>
          Agriculture is the backbone of food security, yet many small-scale farmers face significant challenges. Limited access to modern technology and expert agronomists often forces them into decisions that can inadvertently harm both human health and the environment. In many regions, farmers lacking proper guidance resort to the unregulated use of pesticides—even ones unrelated to the specific plant diseases affecting their crops. This misuse not only degrades the soil ecosystem but also contributes to climate change and poses serious health risks.
        </Typography>
        
        <SectionTitle variant="h5" component="h3">
          {t('The Hidden Toll of Pesticide Overuse')}
        </SectionTitle>
        
        <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}>
          Improper and excessive pesticide application has far-reaching consequences. Studies suggest that the adverse effects of pesticide exposure—whether through direct poisoning or long-term contamination of food—are linked to an estimated 200,000 premature deaths worldwide each year. These figures underscore the urgency of addressing pesticide misuse, as the overapplication of chemicals can lead to a cascade of environmental impacts:
        </Typography>
        
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={12} md={4}>
            <Box sx={{ p: 2, height: '100%', border: '1px solid rgba(16, 163, 127, 0.2)', borderRadius: 1 }}>
              <Typography variant="h6" gutterBottom fontWeight="bold" color="#10a37f">
                Soil Degradation
              </Typography>
              <Typography variant="body2">
                Overused pesticides disrupt beneficial soil microorganisms, reducing fertility and ecosystem resilience.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ p: 2, height: '100%', border: '1px solid rgba(16, 163, 127, 0.2)', borderRadius: 1 }}>
              <Typography variant="h6" gutterBottom fontWeight="bold" color="#10a37f">
                Climate Change
              </Typography>
              <Typography variant="body2">
                Chemical runoff and soil degradation contribute to greenhouse gas emissions and diminish the natural capacity of soils to sequester carbon.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ p: 2, height: '100%', border: '1px solid rgba(16, 163, 127, 0.2)', borderRadius: 1 }}>
              <Typography variant="h6" gutterBottom fontWeight="bold" color="#10a37f">
                Public Health
              </Typography>
              <Typography variant="body2">
                Continuous exposure to pesticide residues in food has been associated with chronic health issues, including neurological disorders and cancer.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        
        <SectionTitle variant="h5" component="h3">
          {t('Our Approach: Smart, Sustainable, and Data-Driven')}
        </SectionTitle>
        
        <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}>
          At Agrihyphen AI, we are committed to transforming agriculture into a more sustainable and climate-resilient industry. Our innovative platform harnesses the power of artificial intelligence to tackle these challenges head-on.
        </Typography>
        
        <SectionTitle variant="h6" component="h4">
          {t('Early Disease Detection')}
        </SectionTitle>
        
        <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}>
          Our system leverages advanced image recognition and machine learning algorithms to analyze high-resolution images of crops. This technology allows us to detect plant diseases at their earliest stages—often before visible symptoms become severe. By identifying issues promptly, we empower farmers to take timely action, reducing the need for blanket pesticide applications.
        </Typography>
        
        <SectionTitle variant="h6" component="h4">
          {t('Tailored Recommendations for Pesticide Use')}
        </SectionTitle>
        
        <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}>
          Once a disease is accurately diagnosed, Agrihyphen AI provides tailored recommendations to farmers. Instead of relying on generic, overused pesticide applications, our system advises on:
        </Typography>
        
        <Box component="ul" sx={{ pl: 4, mb: 3 }}>
          <Typography component="li" variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
            <strong>Optimal Dosage:</strong> Ensuring that only the necessary amount of pesticide is applied.
          </Typography>
          <Typography component="li" variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
            <strong>Targeted Application:</strong> Focusing treatments on affected areas rather than widespread spraying.
          </Typography>
          <Typography component="li" variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
            <strong>Eco-Friendly Alternatives:</strong> In many cases, we recommend biopesticides—natural, environmentally friendly solutions that help manage diseases without compromising the ecosystem.
          </Typography>
        </Box>
        
        <SectionTitle variant="h5" component="h3">
          {t('Restoring Ecosystem Balance and Advancing Climate Action')}
        </SectionTitle>
        
        <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}>
          By reducing the reliance on synthetic chemicals, Agrihyphen AI is making significant contributions to restoring ecological balance:
        </Typography>
        
        <Grid container spacing={3} sx={{ my: 2 }}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ 
              p: 2, 
              height: '100%', 
              backgroundColor: 'rgba(16, 163, 127, 0.05)', 
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography variant="h6" gutterBottom fontWeight="bold" color="#10a37f">
                Enhanced Soil Health
              </Typography>
              <Typography variant="body2">
                Minimizing chemical overuse preserves beneficial microorganisms and supports soil fertility.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ 
              p: 2, 
              height: '100%', 
              backgroundColor: 'rgba(16, 163, 127, 0.05)', 
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography variant="h6" gutterBottom fontWeight="bold" color="#10a37f">
                Improved Food Security
              </Typography>
              <Typography variant="body2">
                Healthier crops mean better yields and safer food, directly benefiting communities.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ 
              p: 2, 
              height: '100%', 
              backgroundColor: 'rgba(16, 163, 127, 0.05)', 
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography variant="h6" gutterBottom fontWeight="bold" color="#10a37f">
                Climate Resilience
              </Typography>
              <Typography variant="body2">
                Sustainable pesticide practices lower the carbon footprint of agricultural operations, aiding in the global fight against climate change.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ 
              p: 2, 
              height: '100%', 
              backgroundColor: 'rgba(16, 163, 127, 0.05)', 
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography variant="h6" gutterBottom fontWeight="bold" color="#10a37f">
                Public Health Protection
              </Typography>
              <Typography variant="body2">
                Reducing pesticide residues in food translates to fewer health risks and improved community well-being.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        
        <SectionTitle variant="h5" component="h3">
          {t('A Vision for the Future')}
        </SectionTitle>
        
        <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}>
          Agrihyphen AI isn't just about disease detection and pesticide management—it's about reshaping the future of agriculture. By equipping farmers with cutting-edge technology and actionable insights, we aim to foster an agricultural landscape that is as kind to the planet as it is productive. Our approach not only enhances crop health and yields but also contributes significantly to restoring ecosystems, strengthening food security, and advancing global climate action.
        </Typography>
        
        <HighlightBox>
          <Typography variant="body1" sx={{ fontStyle: 'italic', fontWeight: 500 }}>
            Join us in our mission to build a more sustainable and resilient world—one field at a time.
          </Typography>
        </HighlightBox>
      </ContentPaper>
    </Container>
  );
};

export default ClimateAction; 