import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  Button, 
  Box,
  useTheme,
  useMediaQuery,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Styled components
const BlogCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  cursor: 'pointer',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  backgroundColor: '#ffffff',
  border: '1px solid rgba(0, 0, 0, 0.08)',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.12)',
  },
}));

const BlogCardMedia = styled(CardMedia)(({ theme }) => ({
  paddingTop: '56.25%', // 16:9 aspect ratio
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%)',
  },
}));

const BlogCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: '#ffffff',
}));

const BlogTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(1),
  lineHeight: 1.3,
  color: '#2d333a',
  fontSize: '1.5rem',
}));

const BlogExcerpt = styled(Typography)(({ theme }) => ({
  color: '#6e7681',
  marginBottom: theme.spacing(2),
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const BlogDate = styled(Typography)(({ theme }) => ({
  color: '#8b949e',
  fontSize: '0.875rem',
}));

const PageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  marginBottom: theme.spacing(6),
  position: 'relative',
  display: 'inline-block',
  color: '#2d333a',
  fontSize: '2.5rem',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -12,
    left: 0,
    width: '40%',
    height: 4,
    backgroundColor: '#10a37f',
  },
}));

const BlogContent = styled(Box)(({ theme }) => ({
  '& h2': {
    fontSize: '2rem',
    fontWeight: 700,
    color: '#2d333a',
    marginBottom: theme.spacing(3),
  },
  '& h3': {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#2d333a',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  '& p': {
    fontSize: '1.1rem',
    lineHeight: 1.7,
    color: '#444d56',
    marginBottom: theme.spacing(3),
  },
  '& ul': {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& li': {
    fontSize: '1.1rem',
    lineHeight: 1.7,
    color: '#444d56',
    marginBottom: theme.spacing(1),
  },
}));

const BackButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: '#10a37f',
  '&:hover': {
    backgroundColor: 'rgba(16, 163, 127, 0.1)',
  },
}));

// Sample blog data
const blogPosts = [
  {
    id: 1,
    title: 'AI Action Summit in Paris',
    excerpt: 'A transformative experience at the AI Action Summit in Paris, where global leaders and innovators gathered to shape the future of artificial intelligence.',
    image: 'https://hyphentech.tech/static/media/yan.8c4f66e7de581de44d74.jpeg',
    content: `
      <h2>AI Action Summit in Paris ✨🌍</h2>
      
      <p>From February 10-11, 2025, I had the privilege of participating in the AI Action Summit in Paris, a truly transformative experience where global leaders, AI pioneers, and innovators came together to shape the future of artificial intelligence.</p>
      
      <h3>Day One: Grand Palais</h3>
      
      <p>The summit took place at the Grand Palais on February 10, and I had the honor of meeting some of the most influential figures in AI and technology, including:</p>
      
      <ul>
        <li>Yann LeCun, Chief AI Scientist at Meta</li>
        <li>Sundar Pichai, CEO of Google</li>
        <li>Arthur Mensch, CEO of Mistral AI</li>
        <li>Alexandr Wang, CEO of Scale AI</li>
      </ul>
      
      <p>President Emmanuel Macron and other ministers shared their vision for AI's role in shaping our societies. During his keynote, Sundar Pichai described this era as the 'Golden Age of Innovation', emphasizing how AI will redefine how future generations live.</p>
      
      <h3>Key Takeaways</h3>
      
      <ul>
        <li>The misconception that AI will 'take jobs' - the real challenge is our lack of preparedness for new roles that AI will create</li>
        <li>The rapid pace of technological change makes it difficult to predict what will be replaced or created</li>
        <li>Growing inequality caused by lack of internet access, infrastructures, and energy in many African countries</li>
      </ul>
      
      <h3>Evening Event: AI & Democracy</h3>
      
      <p>Later that evening at La Maison de la Chimie, we attended an inspiring event on AI & Democracy featuring distinguished speakers:</p>
      
      <ul>
        <li>Henna Virkkunen, Executive Vice-President of the European Commission</li>
        <li>Audrey Tang, Cyber Ambassador and Taiwan's first Digital Minister</li>
        <li>Sviatlana Tsikhanouskaya, National Leader of Belarus</li>
        <li>Oliver Röpke, President of the European Economic and Social Committee</li>
        <li>Frédéric Worms, Director of Ecole Normale Supérieure - PSL</li>
      </ul>
      
      <h3>Day Two: Business Day at Station F1</h3>
      
      <p>On February 11, the Business Day at Station F1 brought together over 800 European startups, showcasing innovative projects and fostering meaningful conversations about the future of AI-driven solutions.</p>
      
      <p>This experience has further strengthened our mission with AgriHyphen AI—to bring AI-powered solutions to African farmers, reducing crop losses and transforming agriculture. Africa must not be left behind in the AI revolution! 🌍🌱</p>
    `,
    date: 'February 11, 2025'
  },
  {
    id: 2,
    title: 'Agrihyphen AI: How AI will enable agriculture to produce better in Africa and Burundi',
    excerpt: 'Exploring the transformative potential of AI in African agriculture at the French Institute of Burundi conference.',
    image: 'https://hyphentech.tech/static/media/ifb-audience.fa6af4d27f77e79d6e8a.png',
    content: `
      <h2>Agrihyphen AI: How AI will enable agriculture to produce better in Africa and Burundi</h2>
      
      <p>We were honored to speak at the French Institute of Burundi on February 3, 2025, during a conference organized by the French Embassy and Campus France Burundi under the theme: 'The Role of Artificial Intelligence in the Jobs of the Future'. 💡</p>
      
      <h3>The Challenge</h3>
      
      <p>Africa's population is growing rapidly, yet we remain heavily dependent on food imports. In Burundi:</p>
      
      <ul>
        <li>Population doubled from 6 million (2000) to 13 million (2025)</li>
        <li>Projected to reach 25 million by 2050</li>
        <li>84% of the population are farmers</li>
        <li>Agriculture contributes 39.6% to GDP</li>
        <li>Farmers lose 40-50% of crops to diseases and pests</li>
        <li>60% loss in economic gains</li>
      </ul>
      
      <p>Eliminating the 40% waste could add $850 million to Burundi's GDP annually! 💡</p>
      
      <h3>AgriHyphen AI Solution</h3>
      
      <p>At AgriHyphen AI, we're pioneering AI-powered solutions to reduce crop waste, improve yields, and ensure food security:</p>
      
      <ul>
        <li>Detects 37 plant diseases with 93.8% accuracy</li>
        <li>Early disease detection reduces crop waste by 90%</li>
        <li>Provides recommendations in multiple local and international languages</li>
      </ul>
      
      <h3>The Future Impact</h3>
      
      <p>According to the World Economic Forum, Human-AI collaboration is projected to add $17.5 trillion to the global economy. We are committed to ensuring Africa benefits from this revolution through local AI innovations like AgriHyphen AI! 🤝</p>
      
      <h3>Join Our Mission</h3>
      
      <p>To scale AgriHyphen AI and reach more farmers, we are looking for partners, funding, computing power, and infrastructure to deploy our AI solutions on a larger scale.</p>
    `,
    date: 'February 03, 2025'
  },
  {
    id: 3,
    title: 'AgriHyphen AI Wins 8.5 Million Burundian Francs at Innovation Week',
    excerpt: 'Recognition as one of the Top 10 Innovators of the Year at the Innovation Week organized by PNUD Burundi.',
    image: 'https://hyphentech.tech/static/media/innovation-week.243af1899dcdd634e3f6.jpg',
    content: `
      <h2>AgriHyphen AI Wins 8.5 Million Burundian Francs at Innovation Week</h2>
      
      <p>We are thrilled to announce that AgriHyphen AI has been recognized as one of the Top 10 Innovators of the Year at the prestigious Innovation Week, organized by PNUD Burundi in partnership with PAEEJ.</p>
      
      <h3>A Remarkable Achievement</h3>
      
      <ul>
        <li>Selected from over 1,100 innovative projects across the country</li>
        <li>Awarded 8.5 million Burundian Francs</li>
        <li>Pitched at the Presidential Palace in Kiriri, Bujumbura</li>
      </ul>
      
      <h3>Impact and Recognition</h3>
      
      <p>This momentous occasion not only validated our efforts but also served as an inspiration to continue pushing the boundaries of AI-driven solutions for agriculture. Our participation in this prestigious event underscored the impact and potential of AgriHyphen AI in addressing critical agricultural challenges in Burundi and beyond.</p>
      
      
      <p>As we celebrate this achievement, we remain focused on our mission to revolutionize agriculture through AI. This is just the beginning of a larger movement to empower farmers, reduce crop losses, and enhance food security across Burundi and Africa.</p>
      
      <h3>Join Our Journey</h3>
      
      <p>We invite everyone to join us on this journey as we continue to innovate, grow, and make a lasting impact. The future of agriculture is smart, and with AgriHyphen AI, that future starts now. Together, let's transform agriculture! 🚀🌾</p>
    `,
    date: 'December 15, 2024'
  }
];

const Blogs = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [selectedBlog, setSelectedBlog] = useState(null);

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
    window.scrollTo(0, 0);
  };

  const handleBackClick = () => {
    setSelectedBlog(null);
    window.scrollTo(0, 0);
  };

  const getLocalizedContent = (content) => {
    // Use French content for Kirundi, English content for Swahili
    if (i18n.language === 'rn') {
      return content; // French content
    }
    if (i18n.language === 'sw') {
      return content; // English content
    }
    return content; // Default to the original content
  };

  if (selectedBlog) {
    return (
      <Container maxWidth="lg" sx={{ mt: 12, mb: 8 }}>
        <BackButton 
          startIcon={<ArrowBackIcon />} 
          onClick={handleBackClick}
        >
          {t('Back to blogs')}
        </BackButton>
        
        <Paper 
          elevation={0} 
          sx={{ 
            p: { xs: 3, md: 5 }, 
            borderRadius: 3,
            border: '1px solid rgba(0, 0, 0, 0.08)',
          }}
        >
          <Typography 
            variant="h3" 
            component="h1" 
            gutterBottom 
            fontWeight="bold"
            color="#2d333a"
          >
            {t(selectedBlog.title)}
          </Typography>
          
          <Typography 
            variant="subtitle1" 
            color="text.secondary" 
            gutterBottom
            sx={{ mb: 4 }}
          >
            {selectedBlog.date}
          </Typography>
          
          <Box 
            component="img" 
            src={selectedBlog.image}
            alt={t(selectedBlog.title)}
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: 2,
              mb: 4,
            }}
          />
          
          <BlogContent 
            dangerouslySetInnerHTML={{ 
              __html: getLocalizedContent(selectedBlog.content)
            }} 
          />
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 12, mb: 8 }}>
      <PageTitle variant="h2" component="h1">
        {t('Blogs')}
      </PageTitle>
      
      <Grid container spacing={4}>
        {blogPosts.map((blog) => (
          <Grid item xs={12} md={6} lg={4} key={blog.id}>
            <BlogCard onClick={() => handleBlogClick(blog)}>
              <BlogCardMedia
                image={blog.image}
                title={t(blog.title)}
              />
              <BlogCardContent>
                <BlogTitle variant="h5">
                  {t(blog.title)}
                </BlogTitle>
                <BlogDate gutterBottom>
                  {blog.date}
                </BlogDate>
                <BlogExcerpt>
                  {t(blog.excerpt)}
                </BlogExcerpt>
                <Button 
                  color="primary" 
                  sx={{ 
                    color: '#10a37f',
                    '&:hover': {
                      backgroundColor: 'rgba(16, 163, 127, 0.1)',
                    },
                  }}
                >
                  {t('Read more')}
                </Button>
              </BlogCardContent>
            </BlogCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Blogs; 